<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          @click="showAllName = !showAllName"
          style="cursor: pointer"
        >
          {{ displayName }}
        </span>
      </template>
      <span>{{ name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ["name"],
  data() {
    return {
      showAllName: false,
    };
  },
  computed: {
    displayName() {
      if (this.showAllName) {
        return this.name;
      } else {
        return this.name.length > 43
          ? `${this.name.slice(0, 43)}...`
          : this.name;
      }
    },
  },
};
</script>

<style></style>
