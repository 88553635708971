<template>
  <v-container :fluid="true" class="broadcasts-wrp">
    <GlobalLoader :is_loading="is_loading" />
    <v-card class="mb-3 contact-filter pa-3">
      <div class="contacts-table__header">
        <div class="d-flex align-center">
          <b>Broadcasts</b>
          <div>
            <v-divider
              style="height: 20px; width: 7px; margin: 0 6px"
              inset
              vertical
            ></v-divider>
            <span style="font-size: 13px">Total</span>:
            <b>{{ count ? count : 0 }}</b>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="searchName"
              append-icon="mdi-magnify"
              label="Search"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="country"
              :items="countries_list"
              :loading="isLoadingCountry"
              :search-input.sync="searchCountry"
              @focus="() => onLoadCountries()"
              item-text="name"
              item-value="id"
              label="Country"
              placeholder="Search"
              clearable
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="user"
              :items="users_list"
              :loading="isLoadingUser"
              :search-input.sync="searchUser"
              @focus="() => onLoadUsers('is_active=true')"
              item-text="display_name"
              label="User"
              placeholder="Search"
              clearable
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-data-table
          class="table-mobile-custom"
          v-if="list && list.length"
          :headers="headers"
          :items="list"
          hide-default-footer
          :loading="loading"
          :itemsPerPage="+pageFilter.limit"
          disable-sort
          dense
        >
          <template v-slot:header.name="{ header }">
            {{ header.text }}
          </template>

          <template v-slot:item.total_links="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.total_links }}</span>
              </template>
              <span>Total links</span> </v-tooltip
            >\<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{
                  item.actual_links || 0
                }}</span>
              </template>
              <span>Actual links</span>
            </v-tooltip>
          </template>
          <template v-slot:item.parts="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.part || 0 }}</span>
              </template>
              <span>Part</span> </v-tooltip
            >\<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.parts || 0 }}</span>
              </template>
              <span>Parts</span>
            </v-tooltip>
          </template>

          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>

          <template v-slot:item.total_clicks="{ item }">
            <span>{{ item.clicks || 0 }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <div>
              <v-chip
                x-small
                :color="getColorBroadcastStatus(item.status)"
                class="mr-1"
              >
                {{ getTitleBroadcastStatus(item.status) }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center name-td" style="min-width: 500px">
              <div class="d-flex align-center">
                <v-menu
                  bottom
                  :close-on-content-click="true"
                  content-class="broadcast-menu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="info" v-bind="attrs" v-on="on" small
                            >mdi-dots-vertical
                          </v-icon>
                        </template>
                        <span>Actions</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-btn
                        small
                        icon
                        :color="getColorBroadcastStatus(item.status)"
                        :title="`Retry ${item.status_comment || ''}`"
                        class="mr-1"
                        @click="retryBroadcast(item.id)"
                      >
                        <v-icon small>mdi-autorenew</v-icon>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        small
                        icon
                        :color="getColorBroadcastStatus(item.shortify_status)"
                        :title="`Fill Shortify ${
                          item.shortify_status_comment || ''
                        }`"
                        class="mr-1"
                        @click="onFillCache(item.id)"
                        :loading="fillCacheLoader"
                      >
                        <v-icon small>mdi-link-variant</v-icon>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        small
                        icon
                        color="success"
                        :title="`Edit`"
                        @click="openEditDialog(item)"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        small
                        icon
                        color="error"
                        :title="`Remove`"
                        @click="removeBroadcast(item)"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                      @click="onCopyToClipboard(item.name)"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy</span>
                </v-tooltip>

                <country-flag
                  v-if="item.country"
                  :country="item.country.value"
                  size="small"
                  :title="item.country.name"
                />
                <div class="mr-2">
                  <BroadcastName :name="item.name" />
                </div>
              </div>

              <div class="d-flex align-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-chip
                        v-for="tag in item.tags"
                        :key="tag"
                        x-small
                        color="info"
                        class="mr-1"
                        outlined
                      >
                        {{ tag }}
                      </v-chip>
                    </div>
                  </template>
                  <span>Tags</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      color="success"
                      class="mr-1"
                    >
                      {{ getTargetName(item.broadcast_target) }}
                    </v-chip>
                  </template>
                  <span>Target</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      color="primary"
                      :href="item.csv"
                      :title="`CSV download`"
                      :disabled="!item.csv"
                    >
                      <v-icon small>mdi-file-download-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>CSV download</span>
                </v-tooltip>
              </div>
            </div>

            <!--          <v-btn text @click="toggleOpenDetailsModal(item.id)">-->
            <!--            {{item.name}}-->
            <!--          </v-btn>-->
          </template>

          <template v-slot:item.user="{ item }">
            <span class="text-capitalize">{{ item.user.username }}</span>
          </template>

          <template v-slot:footer>
            <CustomPagination
              :count="count"
              :limit="pageFilter.limit"
              :offset="pageFilter.offset"
              :changeOffset="
                (value) => {
                  changeFilter({ offset: value });
                }
              "
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <EditBroadcastDialog />
  </v-container>
</template>

<script>
import {
  loadList_v2,
  retryBroadcast_v2,
  fillCache_v2,
} from "@/store/modules/broadcasts/api";
import { mapActions, mapMutations, mapState } from "vuex";
import _ from "lodash";
import CountryFlag from "vue-country-flag";
import {
  onLoadUserInfo,
  updateBroadcast_v2,
} from "../../store/modules/broadcasts/api";
import {
  renderDateTime,
  requestStatus,
  buildFilterString,
  getColorBroadcastStatus,
  getTitleBroadcastStatus,
  BROADCAST_TARGETS,
} from "@/services/services";
import * as BROADCASTS_TYPES from "../../store/modules/broadcasts/types";
import { CHANGE_FILTER } from "../../store/modules/page/types";
import GlobalLoader from "@/components/GlobalLoader.vue";
import EditBroadcastDialog from "./EditBroadcastDialog_v2.vue";
import BroadcastName from "./BroadcastName.vue";
import CustomPagination from "../../components/CustomPagination.vue";

export default {
  name: "BroadcastsTable",
  components: {
    CountryFlag,
    GlobalLoader,
    EditBroadcastDialog,
    BroadcastName,
    CustomPagination,
  },
  props: { toggleOpenDetailsModal: Function },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "User", value: "user" },
      { text: "Status", value: "status" },
      { text: "Links", value: "total_links" },
      { text: "Parts", value: "parts" },
      { text: "Leads", value: "leads" },
      { text: "Revenue", value: "revenue", formatter: (x) => "$" + (x || 0) },
      {
        text: "Clicks",
        value: "total_clicks",
      },
      {
        text: "CTR",
        value: "click_rate",
        formatter: (x) => (x || 0) + "%",
      },
      {
        text: "Created at",
        value: "created_at",
        formatter: (x) => (x ? renderDateTime(x) : null),
      },
    ],
    searchName: "",
    loading: false,
    country: null,
    user: null,
    searchCountry: "",
    searchUser: "",
    isLoadingCountry: false,
    isLoadingUser: false,
    temp: {},
    list: null,
    count: 0,
    is_loading: true,
    fillCacheLoader: false,
  }),
  computed: {
    ...mapState({
      status: (state) => state.broadcasts.status,
      pageFilter: (state) => state.page.filter,
      pageFilterCountry: (state) => +state.page.filter.country || null,
      pageFilterUser: (state) => +state.page.filter.user || null,
      countries: (state) => state.broadcasts.countries,
      users: (state) => state.broadcasts.users,
      countryDetails: (state) => state.broadcasts.countryDetails,
    }),
    getColorBroadcastStatus: () => getColorBroadcastStatus,
    getTitleBroadcastStatus: () => getTitleBroadcastStatus,
    ROADCAST_TARGETS: () => BROADCAST_TARGETS,
    countries_list: function () {
      let results = [...this.countries];
      if (this.country) {
        let default_item = results.filter(
          (item) => +this.country.id === +item.id
        );
        if (!default_item.length) {
          results.push(this.country);
        }
      }
      return results;
    },
    users_list: function () {
      let results = [...this.users];
      if (this.user) {
        let default_item = results.filter((item) => +this.user.id === +item.id);
        if (!default_item.length) {
          results.push(this.user);
        }
      }
      return results;
    },
  },
  destroyed() {
    window.getApp.$off("UPDATE_BROADCAST_v2_LIST");
  },
  mounted() {
    window.getApp.$on("UPDATE_BROADCAST_v2_LIST", () => {
      this.onLoadData(this.pageFilter);
    });
  },
  methods: {
    ...mapActions({
      onLoadCountries: `broadcasts/${BROADCASTS_TYPES.LOAD_COUNTRIES}`,
      onLoadUsers: `broadcasts/${BROADCASTS_TYPES.LOAD_USERS}`,
      onLoadCountryDetails: `broadcasts/${BROADCASTS_TYPES.LOAD_COUNTRY_DETAILS}`,
      createFile: `broadcasts/${BROADCASTS_TYPES.CREATE_FILE}`,
    }),
    ...mapMutations({
      changeFilter: `page/${CHANGE_FILTER}`,
    }),
    removeBroadcast(item) {
      window.getApp.$refs.global_confirm_modal
        .open("Confirm remove broadcast")
        .then((confirm) => {
          if (confirm) {
            updateBroadcast_v2(item.id, {
              is_archived: true,
              name: item.name,
            })
              .then((response) => {
                if (response.status === requestStatus.success) {
                  this.onLoadData(this.pageFilter);
                  this.$toaster.success("Broadcast removed");
                } else {
                  this.$toaster.error(
                    response.messages || "Oops, something went wrong."
                  );
                }
              })
              .finally(() => {
                this.is_loading = false;
              });
          }
        });
    },
    onCopyToClipboard: function (name) {
      let body = window.parent.document.querySelector("body");
      let input = window.parent.document.createElement("input");
      body.appendChild(input);
      input.value = name;
      input.select();
      document.execCommand("copy");
      body.removeChild(input);
      this.$toaster.success("Copy success");
      return true;
    },
    openEditDialog(item) {
      window.getApp.$emit("OPEN_BROADCAST_UPDATE_DIALOG", item);
    },
    retryBroadcast(id) {
      retryBroadcast_v2(id).then((response) => {
        if (response.status === requestStatus.success) {
          this.$toaster.success("Success retry");
        } else {
          this.$toaster.error(
            response.messages || "Oops, something went wrong."
          );
        }
      });
    },
    onFillCache(id) {
      this.fillCacheLoader = true;
      fillCache_v2(id)
        .then((response) => {
          if (response.status === requestStatus.success) {
            this.$toaster.success("Success retry");
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => (this.fillCacheLoader = false));
    },
    onLoadData(pageFilter) {
      this.is_loading = true;
      loadList_v2(
        `${buildFilterString(
          pageFilter
        )}&fields=id,name,tags,country,user,total_links,broadcast_target,created_at,clicks,click_rate,csv,shortify_status_comment,shortify_status,file_status_comment,file_status,status_comment,status,leads,revenue,actual_links,parts,part`
      )
        .then((response) => {
          if (response.status === requestStatus.success) {
            this.list = response.response.data.results;
            this.count = response.response.data.count;
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => (this.is_loading = false));
    },

    onWaitChange() {
      if (
        this.searchName !== this.pageFilter.search &&
        (this.searchName.length >= 2 || this.searchName === "")
      ) {
        this.changeFilter({ search: this.searchName });
      }
    },

    onWaitChangeCountry() {
      if (
        this.searchCountry &&
        (this.searchCountry.length >= 2 || this.searchCountry === "")
      ) {
        this.onLoadCountries(`search=${this.searchCountry}`);
      }
    },
    onWaitChangeUser() {
      if (
        this.searchUser &&
        (this.searchUser.length >= 2 || this.searchUser === "")
      ) {
        this.onLoadUsers(
          `search=${this.searchUser}${
            !this.searchUser ? "&is_active=true" : ""
          }`
        );
      }
    },

    isLoadingAction(field, id) {
      return (
        this.status[field] === requestStatus.loading && this.status.id === id
      );
    },
    getTargetName(value) {
      return BROADCAST_TARGETS.find((i) => i.value === value).name;
    },
  },
  watch: {
    status(newValue) {
      this.loading = newValue.load_list === requestStatus.loading;
      this.isLoadingCountry = newValue.load_countries === requestStatus.loading;
      this.isLoadingUser = newValue.load_users === requestStatus.loading;
    },
    pageFilter(newValue) {
      this.onLoadData(newValue);
    },
    country(newValue) {
      this.changeFilter({ country: newValue ? newValue.id : null });
    },
    user(newValue) {
      this.changeFilter({ user: newValue ? newValue.id : null });
    },
    countryDetails(newValue) {
      this.country = newValue;
    },
    searchName() {
      this.onWaitChange();
    },
    searchCountry(newValue) {
      if (!this.country || newValue !== this.country.name) {
        this.onWaitChangeCountry();
      }
    },
    searchUser(newValue) {
      if (!this.user || newValue !== this.user.display_name) {
        this.onWaitChangeUser();
      }
    },
  },

  created() {
    this.searchName = this.pageFilter.search || "";
    this.onLoadData(this.pageFilter);
    this.onWaitChange = _.debounce(this.onWaitChange, 500);
    this.onWaitChangeCountry = _.debounce(this.onWaitChangeCountry, 500);
    this.onWaitChangeUser = _.debounce(this.onWaitChangeUser, 500);
    if (this.pageFilterCountry) {
      this.onLoadCountryDetails(this.pageFilterCountry);
    }
    // Load user info from filter
    if (this.pageFilterUser) {
      onLoadUserInfo(this.pageFilterUser).then((response) => {
        if (response?.response?.data)
          this.user = {
            id: response?.response?.data.id,
            display_name: response?.response?.data.username,
          };
      });
    }
  },
};
</script>

<style lang="scss">
.broadcast-menu {
  .v-list-item {
    min-height: 20px;
    padding: 4px;
  }
}

@media (max-width: 600px) {
  .table-mobile-custom {
    table {
      .v-data-table__mobile-row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .v-data-table__mobile-row__header {
        color: #a3a3a3;
        font-size: 12px;
      }
      .v-data-table__mobile-table-row {
        border: 1px solid #e6e6e6;
        margin-bottom: 4px;
        border-radius: 4px;
      }
      .v-data-table__mobile-row {
        padding: 6px !important;
        border-bottom: 1px solid #eeeeee;
      }

      .name-td {
        min-width: 0 !important;
        flex-direction: column;
        align-items: start !important;

        div {
          align-items: flex-start;
        }
      }
    }
  }
}
</style>
