<template>
  <v-dialog :value="isOpen" persistent max-width="600px">
    <v-card v-if="broadcast">
      <v-card-title>
        <span class="text-h5">Update Broadcast</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="broadcast.name"
                label="Name"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <tags ref="tags" :propsTags="broadcast.tags"/>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="broadcast.actual_links"
                label="Actual links"
                persistent-hint
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          :loading="is_loading"
          text
          @click="isOpen = false"
        >
          Close
        </v-btn>
        <v-btn
          color="blue darken-1"
          :loading="is_loading"
          text
          @click="updateBroadcastEvent"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Tags from "@/components/Tags";
import _ from "lodash";
import { updateBroadcast_v2 } from "@/store/modules/broadcasts/api";
import { requestStatus } from "@/services/services";

export default {
  name: "CreateBroadcast",
  components: { Tags },
  data: () => ({
    isOpen: false,
    broadcast: null,
    is_loading: false,
  }),
  mounted() {
    window.getApp.$on("OPEN_BROADCAST_UPDATE_DIALOG", (item) => {
      this.broadcast = JSON.parse(JSON.stringify(item));
      this.isOpen = true;
    });
  },
  watch: {
    isOpen() {
      if (!this.isOpen) {
        this.broadcast = null;
      }
    },
  },
  methods: {
    updateBroadcastEvent() {
      this.is_loading = true;
      updateBroadcast_v2(this.broadcast.id, {
        name: this.broadcast.name,
        actual_links: this.broadcast.actual_links,
        tags: this.$refs.tags.getTagsList()
      })
        .then((response) => {
          if (response.status === requestStatus.success) {
            window.getApp.$emit("UPDATE_BROADCAST_v2_LIST");
            this.isOpen = false;
            this.$toaster.success("Broadcast updated");
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
